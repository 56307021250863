const FinanceIndex = () => import('@/modules/finances/views/FinanceIndex.vue')

export default [
  {
    path: '/finances',
    name: 'finances.index',
    component: FinanceIndex,
    meta: { requiresAuthentication: true }
  }
]
