const EntryShow = () => import('@/modules/help-center/views/EntryShow.vue')
const HelpCenterIndex = () => import('@/modules/help-center/views/HelpCenterIndex.vue')

export default [
  {
    path: '/help-center/entry/:slug',
    name: 'help-center.entry.show',
    component: EntryShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/help-center/search',
    name: 'help-center.search',
    component: HelpCenterIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/help-center',
    name: 'help-center.index',
    component: HelpCenterIndex,
    meta: { requiresAuthentication: true }
  }
]
