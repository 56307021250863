import {
  SET_ERRORS,
  SET_NOTIFICATION,
  SET_IS_SIDEBAR_OPEN
} from './mutation-types'

export default {
  [SET_ERRORS]: (state, { errors }) => {
    state.errors = errors
  },
  [SET_NOTIFICATION]: (state, { notification }) => {
    state.notification = notification
  },
  [SET_IS_SIDEBAR_OPEN]: (state, status) => {
    state.isSidebarOpen = status
  }
}
