import Vue from 'vue'
import VueI18n from 'vue-i18n'

// translations
import en from '../locales/en.json'
import es from '../locales/es.json'
import ptBR from '../locales/pt-BR.json'

Vue.use(VueI18n)

const messages = {
  en: en,
  es: es,
  pt_BR: ptBR
}

const i18n = new VueI18n({
  locale: 'pt_BR',
  fallbackLocale: ['pt_BR', 'en'],
  messages
})

export default i18n
