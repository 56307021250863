import axios from '@/plugins/axios'
import store from '@/store'

/**
 * Activate user
 */
const activateUser = async id => {
  try {
    const response = await axios.post(`users/${id}/activate`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update user data
 *
 * @param {*} id
 * @param {*} param1
 * @returns
 */
const changeLanguage = async (id, language) => {
  try {
    const response = await axios.put(`users/${id}/language`, { language: language })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Create user
 */
const createUser = async ({
  name,
  email,
  // eslint-disable-next-line camelcase
  role_id
}) => {
  try {
    const form = {
      name: name,
      email: email,
      role_id: role_id
    }
    const response = await axios.post('users', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Destroy user
 */
const destroyUser = async id => {
  try {
    const response = await axios.post(`users/${id}`, {
      _method: 'DELETE'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get all users
 */
const getUsers = async ({ companyId }) => {
  try {
    const params = {
      company_id: companyId
    }
    const response = await axios.get('users', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get available user roles
 */
const getUserRoles = async () => {
  try {
    const response = await axios.get('roles')
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get current logged user
 *
 * @returns
 */
const me = async function () {
  try {
    const response = await axios.get('users/me')
    const { data } = response

    store.dispatch('users/setUser', { user: data })

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * set cookie
 */
const setCookie = async (name, value, days) => {
  const date = new Date()
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)) // Define expiração em dias
  const expires = 'expires=' + date.toUTCString()
  document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

/**
 * get cookie
 */
const getCookie = async (name) => {
  const nameEQ = name + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieArray = decodedCookie.split(';')
  for (let i = 0; i < cookieArray.length; i++) {
    const cookie = cookieArray[i].trim()
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length)
    }
  }
  return null
}

/**
 * Toggle user's 2FA
 *
 * @param {*} id
 * @returns
 */
const toggle2FA = async id => {
  try {
    const response = await axios.post(`users/${id}/two-factor-authentication`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Toggle user's emaisl enabled
 *
 * @param {*} id
 * @returns
 */
const toggleEmailsEnabled = async id => {
  try {
    const response = await axios.post(`users/${id}/emails-enabled`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Toggle user's whatsapp enabled
 *
 * @param {*} id
 * @returns
 */
const toggleWhatsappEnabled = async id => {
  try {
    const response = await axios.post(`users/${id}/whatsapp-enabled`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update user data
 *
 * @param {*} id
 * @param {*} param1
 * @returns
 */
const updateUser = async (id, {
  name = null,
  email = null,
  phone = null,
  // eslint-disable-next-line camelcase
  role_id = null
}) => {
  try {
    const form = {
      name: name,
      email: email,
      phone: phone,
      _method: 'PUT'
    }
    // eslint-disable-next-line camelcase
    if (role_id) form.role_id = role_id
    const response = await axios.post(`users/${id}`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  activateUser,
  changeLanguage,
  createUser,
  destroyUser,
  getUsers,
  getUserRoles,
  me,
  getCookie,
  setCookie,
  toggle2FA,
  toggleEmailsEnabled,
  toggleWhatsappEnabled,
  updateUser
}
