const PasswordCreate = () => import('@/modules/auth/views/PasswordCreate.vue')
const PasswordReset = () => import('@/modules/auth/views/PasswordReset.vue')

export default [
  {
    name: 'password.create',
    path: '/password/create/:token',
    component: PasswordCreate
  },
  {
    name: 'password.reset',
    path: '/password/reset/:token',
    component: PasswordReset
  }
]
