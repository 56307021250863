const UserSettings = () => import('@/modules/users/views/UserSettings.vue')

export default [
  {
    path: '/settings',
    name: 'users.settings.show',
    component: UserSettings,
    meta: { requiresAuthentication: true }
  }
]
