import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthStore from '@/modules/auth/store'
import store from '@/store'

import advertisersRoutes from '@/modules/advertisers/router'
import authRoutes from '@/modules/auth/router'
import campaignsRoutes from '@/modules/campaigns/router'
import companiesRoutes from '@/modules/companies/router'
import dashboardsRoutes from '@/modules/dashboards/router'
import financesRoutes from '@/modules/finances/router'
import helpCenterRoutes from '@/modules/help-center/router'
import integrationsRoutes from '@/modules/integrations/router'
import ordersRoutes from '@/modules/orders/router'
import pixelsRoutes from '@/modules/pixels/router'
import reportsRoutes from '@/modules/reports/router'
import staticsRoutes from '@/modules/statics/router'
import usersRoutes from '@/modules/users/router'

Vue.use(VueRouter)

const Home = () => {
  return AuthStore.state.authenticated
    ? import('@/modules/home/views/Home.vue')
    : import('@/modules/home/views/Login.vue')
}

const NotFound = () => import('@/modules/404/views/404.vue')

const routes = [
  {
    path: '/',
    component: Home,
    name: 'home'
  },
  ...advertisersRoutes,
  ...authRoutes,
  ...campaignsRoutes,
  ...companiesRoutes,
  ...dashboardsRoutes,
  ...financesRoutes,
  ...ordersRoutes,
  ...helpCenterRoutes,
  ...integrationsRoutes,
  ...pixelsRoutes,
  ...reportsRoutes,
  ...staticsRoutes,
  ...usersRoutes,
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('clearErrors')
  if (to.matched.some(route => route.meta.requiresNotAuthenticated)) {
    if (AuthStore.state.authenticated) {
      return next({ path: '/' })
    }
  }
  if (to.matched.some(route => route.meta.requiresAuthentication)) {
    if (!AuthStore.state.authenticated) {
      return next({ path: '/' })
    }
  }
  next()
})

export default router
