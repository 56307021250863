const seo = {
  data () {
    return {
      seoDefaultDescription: 'Plataforma de Mídia Programática.',
      seoDefaultTitle: 'Pixel Roads',
      seoDefaultTitleAppend: 'Pixel Roads',
      seoDefaultImage: `${process.env.VUE_APP_BASE_URL}/images/facebook-og-v2.png`
    }
  }
}

export default seo
