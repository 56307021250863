const CreatePayment = () => import('@/modules/orders/views/CreatePayment.vue')
const NFSeShow = () => import('@/modules/orders/views/NFSeShow.vue')

export default [
  {
    path: '/orders/:id/payment',
    name: 'orders.payment.create',
    component: CreatePayment
  },
  {
    path: '/orders/:id/nfse',
    name: 'orders.nfse.show',
    component: NFSeShow,
    meta: { requiresAuthentication: true }
  }
]
