import Vue from 'vue'
import '@/plugins/moment'
// import moment from 'moment-timezone'
import moment from 'moment'
import i18n from '@/plugins/vue-i18n'

Vue.filter('formatDate', function (date, format = 'DD/MM/YYYY') {
  if (i18n.locale === 'en') return moment(date).format('MM/DD/YYYY')
  else return moment(date).format(format)
})
