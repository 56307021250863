<template>
  <button
      :type="btnType"
      class="btn"
      :class="{
        'is-loading': loading,
        'soft-disabled': softDisabled
      }"
      @click="clickEvent($event)"
  >
    <span v-show="!loading">
      <slot />
    </span>
    <span class="loader" v-if="loading">
      <Loader
        :size="'small'"
        :align="'center'"
      />
    </span>
  </button>
</template>

<script>

import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'Button',
  components: {
    Loader
  },
  props: ['type', 'loading', 'softDisabled'],
  computed: {
    btnType () {
      return this.type ? this.type : 'button'
    }
  },
  methods: {
    clickEvent (event) {
      if (this.softDisabled) return
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn.is-loading {
  pointer-events: none;
  opacity: 0.8;
}
.btn.soft-disabled {
  opacity: 0.65;
}
</style>
