const CompanyShow = () => import('@/modules/companies/views/CompanyShow.vue')

export default [
  {
    path: '/company',
    name: 'companies.show',
    component: CompanyShow,
    meta: { requiresAuthentication: true }
  }
]
