<template>
  <div :class="cssAlign">
    <div class="loader" :class="cssClass">
      <div v-for="index in squares" :key="index" class="square"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: ['color', 'size', 'align'],
  computed: {
    cssClass () {
      return [
        `${this.color}`,
        `size-${this.size}`
      ]
    },
    cssAlign () {
      if (this.align) {
        return [
          'd-flex',
          `justify-content-${this.align}`
        ]
      } else {
        return [
          'd-flex',
          'justify-content-center'
        ]
      }
    },
    squares () {
      if (this.size === 'big') return 16
      else return 9
    }
  }
}
</script>
