import axios from '@/plugins/axios'
import store from '@/store'

// eslint-disable-next-line camelcase
const createPassword = async ({ token, email, password, password_confirmation }) => {
  try {
    const params = {
      token,
      email,
      password,
      password_confirmation
    }
    const response = await axios.post('password/create', params)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Impersonate user by token
 */
const impersonate = async (token, originalToken = null) => {
  // Store original token as original_access_token
  if (originalToken) localStorage.setItem('original_access_token', originalToken)
  // Store new token as access token
  localStorage.setItem('access_token', token)

  return Promise.resolve(true)
}

/**
 * Login user
 *
 * @param {*} variables
 * @returns
 */
const login = async function (variables) {
  try {
    const user = {
      use_2fa: false,
      email: null,
      is_email_verified: false
    }

    const response = await axios.post('login', variables)
    const { data } = response

    user.is_email_verified = data.user.is_email_verified
    user.email = data.user.email

    // If use 2fa in login, dont save access_token
    if (typeof data === 'object' && data.user.use_2fa) {
      user.use_2fa = true
      return user
    }

    // If has access token, save it on session
    if (data.access_token) onLogin(data.access_token)
    return user
  } catch (error) {
    return Promise.reject(error)
  }
}

const register = async variables => {
  try {
    const response = await axios.post('register', variables)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Logout
 *
 * @returns
 */
const logout = async () => {
  try {
    await axios.post('logout')
    onLogout()
    return true
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Send email to reset password
 *
 * @param {*} email
 * @returns
 */
const sendResetPasswordEmail = async email => {
  try {
    const response = await axios.post('password/email', {
      email: email
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

// eslint-disable-next-line camelcase
const sendResetPassword = async ({ token, email, password, password_confirmation }) => {
  try {
    const params = {
      token,
      email,
      password,
      password_confirmation
    }
    const response = await axios.post('password/reset', params)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Stop impersonate
 */
const stopImpersonate = async () => {
  // Invalidate current token
  await axios.post('logout')

  // Check if has original access token
  const originalToken = localStorage.getItem('original_access_token')
  if (originalToken) {
    // If has token, overwrite current token with original and then delete it
    localStorage.setItem('access_token', originalToken)
    localStorage.removeItem('original_access_token')
  } else {
    // If doesn't have original token, just erase current token
    localStorage.removeItem('access_token')
  }

  return Promise.resolve(true)
}

/**
 * Send 2FA code again to email
 *
 * @param {*} param0
 * @returns
 */
const resend2FACode = async ({ email }) => {
  try {
    const params = {
      email: email
    }
    const response = await axios.post('two-factor-authentication/request', params)
    const { data } = response

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Send confirmation code again to email
 *
 * @param {*} param0
 * @returns
 */
const resendConfirmationCode = async ({ email }) => {
  try {
    const params = {
      email: email
    }
    const response = await axios.post('confirmation-code/request', params)
    const { data } = response

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

const onLogout = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('original_access_token')
  store.dispatch('auth/setAuthenticated', { authenticated: false }, { root: true })
}

const onLogin = function (token) {
  localStorage.setItem('access_token', token)
  store.dispatch('auth/setAuthenticated', { authenticated: true }, { root: true })
}

/**
 * Validate 2FA code on backend
 *
 * @param {*} param0
 * @returns
 */
const validate2FACode = async ({ code, email }) => {
  try {
    const params = {
      code,
      email
    }
    const response = await axios.post('two-factor-authentication/authenticate', params)
    const { data } = response
    onLogin(data.access_token)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Verify email
 */
const verifyEmail = async ({ email, code }) => {
  try {
    const params = {
      email: email,
      code: code
    }
    const response = await axios.post('register/verify', params)
    const { data } = response
    onLogin(data.access_token)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  createPassword,
  impersonate,
  login,
  register,
  sendResetPassword,
  sendResetPasswordEmail,
  stopImpersonate,
  onLogin,
  onLogout,
  logout,
  resend2FACode,
  resendConfirmationCode,
  validate2FACode,
  verifyEmail
}
