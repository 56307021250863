<template>
  <footer class="py-4">
    <div class="container text-center">
      <p class="fw-bold mb-1">{{ $t('contact_us') }} <a href="mailto:falecom@pixelroads.com.br" class="link-color5">falecom@pixelroads.com.br</a>.</p>
      <p class="mb-0 fw-bold">
        Pixel Roads - © 2024 | <router-link :to="{ name: 'statics.terms' }" class="link-color5">{{ $t('terms_of_use_privacy_policy') }}</router-link>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>

<style lang="scss" scoped>
footer {
  height: 93px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .container {
    position: relative;
    z-index: 2;
  }

  p {
    font-size: 14px;
  }
}

.login {
  footer {
    position: relative;

    p {
      font-size: 13px;
    }
  }
}
</style>
