<template>
  <div>
    <div class="alert alert-block alert-dismissible" :class="alertClasses" :style="{ fontSize: (fontSize || 12) + 'px' }">
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          @click.prevent="$emit('dismiss-alert', $event)"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        <template v-if="content">
          <ul v-if="Array.isArray(content)" class="mb-0">
            <li v-for="(c, index) in content" :key="`c-${index}`">{{ c }}</li>
          </ul>
          <div v-else v-html="content"></div>
        </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseAlert',
  props: ['content', 'type', 'message', 'fontSize'],
  computed: {
    alertClasses () {
      const array = []
      switch (this.type) {
        case 'error' :
          array.push('alert-danger')
          break
        case 'success' :
          array.push('alert-success')
          break
        case 'warning' :
          array.push('alert-message-card')
          break
        default:
          array.push('alert-info')
      }

      if (this.content && this.type === 'error') array.push('alert-error-list')

      return array
    }
  }
}
</script>

<style lang="scss" scoped>
  #pop-up-pixel-roads {
    .alert-message-card {
      padding: 30px !important;
    }
  }
</style>
