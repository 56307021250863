import Vue from 'vue'

// This variable will hold the reference to
// document's click handler
let handleOutsideClick
let directiveShouldCheck

Vue.directive('closable', {
  bind (el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    directiveShouldCheck = binding.value.shouldCheck
    handleOutsideClick = (e) => {
      // console.log('check', directiveShouldCheck)
      if (directiveShouldCheck) {
        e.stopPropagation()
        // Get the handler method name and the exclude array
        // from the object used in v-closable
        const { handler, exclude } = binding.value
        // This variable indicates if the clicked element is excluded
        let clickedOnExcludedEl = false
        exclude.forEach(className => {
          // We only run this code if we haven't detected
          // any excluded element yet
          if (!clickedOnExcludedEl) {
            // Check to see if the clicked element or parents has one of the classes
            let el = e.target
            while (el.parentElement) {
              if (el.classList.contains(className)) {
                clickedOnExcludedEl = true
                break
              }
              el = el.parentElement
            }
            // const excludedEl = vnode.context.$refs[refName]
            // let excludedDomEl = null
            // if (excludedEl) {
            //   // If it's a vue component grab the element, otherwise it is the element
            //   excludedDomEl = excludedEl.$el ? excludedEl.$el : excludedEl
            //   clickedOnExcludedEl = excludedDomEl.contains(e.target)
            // }
          }
        })

        // We check to see if the clicked element is not
        // the dialog element and not excluded
        if (!el.contains(e.target) && !clickedOnExcludedEl) {
          // If the clicked element is outside the dialog
          // and not the button, then call the outside-click handler
          // from the same component this directive is used in
          vnode.context[handler]()
        }
      }
    }
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  componentUpdated (el, binding, vnode) {
    const { shouldCheck } = binding.value
    directiveShouldCheck = shouldCheck
    // console.log(directiveShouldCheck)
  },
  unbind () {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
})
