<template>
  <aside
    id="sidebar"
    v-closable="{
      exclude: ['actionToggleSidebar'],
      shouldCheck: isSidebarOpen,
      handler: 'hide'
    }"
    :class="{
      open: isSidebarOpen
    }"
  >
    <ul class="list-unstyled mb-0">
      <li class="mb-3">
        <router-link :to="{ name: 'advertisers.index' }">
          <span>
            <img src="/images/layout/icon-advertisers.svg" alt="icon" class="icon-menu" />
          </span>
          {{ $t('advertisers') }}
        </router-link>
      </li>
      <li class="mb-3">
        <a href="#" @click.prevent="openAudiencesModal">
          <span>
            <img src="/images/layout/icon-audiences.svg" alt="icon" class="icon-menu" />
          </span>
          {{ $t('available_audiences') }}
        </a>
      </li>
      <li class="mb-3">
        <router-link :to="{ name: 'campaigns.index' }">
          <span>
            <img src="/images/layout/icon-campaigns.svg" alt="icon" class="icon-menu" />
          </span>
          {{ $t('campaigns') }}
        </router-link>
      </li>
      <li class="mb-3">
        <router-link :to="{ name: 'pixels.index' }">
          <span>
            <img src="/images/layout/icon-pixels.svg" alt="icon" class="icon-menu" />
          </span>
          {{ $t('pixels') }}
        </router-link>
      </li>
      <li class="mb-3">
        <router-link :to="{ name: 'reports.index' }">
          <span>
            <img src="/images/layout/icon-reports.svg" alt="icon" class="icon-menu" />
          </span>
          {{ $t('reports') }}
        </router-link>
      </li>
      <li class="mb-3" v-if="user && (user.role.slug === 'user-manager' || user.role.slug === 'administrator')">
        <router-link :to="{ name: 'finances.index' }">
          <span>
            <img src="/images/layout/icon-financial.svg" alt="icon" class="icon-menu" />
          </span>
          {{ $t('financial') }}
        </router-link>
      </li>
      <li v-if="user && (user.role.slug === 'user-manager' || user.role.slug === 'administrator')" class="mb-0">
        <router-link :to="{ name: 'companies.show' }">
          <span>
            <img src="/images/layout/icon-my-company.svg" alt="icon" class="icon-menu" />
          </span>
          {{ $t('my_company') }}
        </router-link>
      </li>
      <li class="mt-3">
        <a v-b-modal="'support-modal'">
          <span>
            <img src="/images/layout/icon-support.svg" alt="icon" class="icon-menu" />
          </span>
          {{ $t('questions_and_support') }}
        </a>
      </li>
      <li class="d-lg-none mt-3 mb-3">
        <router-link :to="{ name: 'users.settings.show' }">
          <span>
            <img src="/images/layout/icon-user.svg" alt="icon" class="icon-menu" />
          </span>
          {{ $t('my_account') }}
        </router-link>
      </li>
      <li class="d-lg-none">
        <a href="#" @click.prevent="logout">
          <span>
            <img src="/images/layout/icon-go-out.svg" alt="icon" class="icon-menu" />
          </span>
          {{ $t('logout') }}
        </a>
      </li>
    </ul>
    <SelectAudienceModal
      :exploreOnly="true"
    />
  </aside>
</template>

<script>

import AuthService from '@/modules/auth/services/auth-service'
import { mapActions, mapState } from 'vuex'

import SelectAudienceModal from '@/components/audiences/SelectAudienceModal'

export default {
  name: 'TheSidebar',
  components: {
    SelectAudienceModal
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    ...mapState({
      isSidebarOpen: state => state.isSidebarOpen
    })
  },
  methods: {
    ...mapActions(['setIsSidebarOpen']),
    /**
     * Hide sidebar
     */
    hide () {
      if (this.isSidebarOpen && window.innerWidth < 992) {
        this.setIsSidebarOpen(false)
      }
    },
    /**
     * Logout
     */
    async logout () {
      try {
        await AuthService.logout()
        this.$router.push('/')
        location.reload()
      } catch (error) {
      }
    },
    /**
     * Open audiences modal
     */
    openAudiencesModal () {
      this.$bvModal.show('explore-audience-modal')
    }
  }
}
</script>

<style lang="scss" scoped>

#sidebar {
  position: fixed;
  width: 250px;
  background: #fff;
  z-index: 3;
  left: -250px;
  top: 0;
  height: 100%;
  transition: all 0.2s ease-out;
  padding: 25px;

  @include desktop {
    left: 0;
    top: 100px;
    width: 130px;
    height: auto;
  }

  &.open {
    left: 0;
  }

  li {
    text-align: center;
    font-size: $font_size_small;

    span {
      display: block;
    }

    i {
      font-size: 26px;
    }
  }

  a {
    color: $color9;
    display: flex;
    align-items: center;

    @include desktop {
      display: block;
    }

    span {
      margin-right: 15px;
      height: 30px;
      color: $color5;

      img {
        width: 25px;
      }

      @include desktop {
        width: auto;
        margin-right: 0;

        img {
          width: 30px;
        }
      }
    }

    &:hover {
      color: $color5;
    }
  }
}

</style>
