import axios from '@/plugins/axios'

/**
 * Get audiences
 */
const getAudiences = async ({
  // eslint-disable-next-line camelcase
  category_id,
  name,
  page,
  type = null
}) => {
  try {
    const params = {
      page: page
    }

    // eslint-disable-next-line camelcase
    if (category_id) params.category_id = category_id

    if (name) params.name = name
    if (type) params.type = type

    const response = await axios.get('audiences', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getAudiences
}
