const PixelIndex = () => import('@/modules/pixels/views/PixelIndex.vue')
const PixelShow = () => import('@/modules/pixels/views/PixelShow.vue')

export default [
  {
    path: '/pixels',
    name: 'pixels.index',
    component: PixelIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/pixels/:id',
    name: 'pixels.show',
    component: PixelShow,
    meta: { requiresAuthentication: true }
  }
]
