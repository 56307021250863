import actions from './actions'
import mutations from './mutations'

const state = {
  authenticated: !!localStorage.getItem('access_token'),
  isImpersonating: !!localStorage.getItem('original_access_token')
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
