import * as types from './mutation-types'

export default {
  setAuthenticated ({ commit }, payload) {
    commit(types.SET_AUTHENTICATED, payload)
  },
  setIsImpersonating ({ commit }, payload) {
    commit(types.SET_IS_IMPERSONATING, payload)
  }
}
