const DashboardIndex = () => import('@/modules/dashboards/views/DashboardIndex.vue')
const DashboardShow = () => import('@/modules/dashboards/views/DashboardShow.vue')

export default [
  {
    path: '/dashboards',
    name: 'dashboards.index',
    component: DashboardIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/dashboards/:id',
    name: 'dashboards.show',
    component: DashboardShow,
    meta: { requiresAuthentication: true }
  }
]
