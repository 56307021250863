const ReportIndex = () => import('@/modules/reports/views/ReportIndex.vue')

export default [
  {
    path: '/reports',
    name: 'reports.index',
    component: ReportIndex,
    meta: { requiresAuthentication: true }
  }
]
