import Vue from 'vue'

Vue.filter('formatNumber', function (number) {
  return new Intl.NumberFormat('pt-BR').format(number)
})

Vue.filter('formatMoney', function (number, currency = 'BRL') {
  let decSep, thouSep, decPlaces, currencySymbol

  // Define settings based on the currency
  switch (currency) {
    case 'USD':
      decSep = '.'
      thouSep = ','
      currencySymbol = '$'
      decPlaces = 2
      break
    case 'EUR':
      decSep = ','
      thouSep = '.'
      currencySymbol = '€'
      decPlaces = 2
      break
    default:
      decSep = ','
      thouSep = '.'
      currencySymbol = 'R$'
      decPlaces = 2
      break
  }

  const sign = number < 0 ? '-' : ''
  const i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
  const j = i.length > 3 ? i.length % 3 : 0

  return currencySymbol + ' ' + sign +
    (j ? i.substr(0, j) + thouSep : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thouSep) +
    (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : '')
})

/**
 * Format filesize
 */
Vue.filter('formatFileSize', function (size) {
  if (size < 1000) {
    return size + ' bytes'
  } else if (size >= 1000 && size < 1000000) {
    return Math.ceil((size / 1000)) + 'KB'
  } else {
    return Math.ceil((size / 1000000)) + 'MB'
  }
})
