import {
  SET_AUTHENTICATED,
  SET_IS_IMPERSONATING
} from './mutation-types'

export default {
  [SET_AUTHENTICATED]: (state, { authenticated }) => {
    state.authenticated = authenticated
  },
  [SET_IS_IMPERSONATING]: (state, { isImpersonating }) => {
    state.isImpersonating = isImpersonating
  }
}
