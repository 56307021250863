const GoogleOAuthRedirectShow = () => import('@/modules/integrations/views/OAuthRedirectShow.vue')
const MetaOAuthRedirectShow = () => import('@/modules/integrations/views/OAuthRedirectShow.vue')

export default [
  {
    path: '/google-oauth-redirect',
    name: 'integrations.google-oauth-redirect.show',
    component: GoogleOAuthRedirectShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/meta-oauth-redirect',
    name: 'integrations.meta-oauth-redirect.show',
    component: MetaOAuthRedirectShow,
    meta: { requiresAuthentication: true }
  }
]
